/* Survey Container */
.survey-form {
  max-width: 600px; /* Max width for larger screens */
  margin: 0 auto;
  padding: 20px;
  
  /* Label Styles */
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: light;
    text-align: left;
    color: var(--color-black);
    font-size: 0.9rem;
    font-weight: bold;
    margin-top: 25px;
    text-transform: uppercase;
  }
  
  /* Radio Button and its Label Styles */
  /* Hide the default radio button */
  .radio-group input[type="radio"] {
    appearance: none;
    position: absolute;
    opacity: 0;
  }
  
  /* Custom Radio Button Styles */
  .radio-group input[type="radio"] + span {
    height: 20px;
    width: 20px;
    border-radius: 50%; /* Circular shape */
    display: inline-block;
    margin-right: 10px;
    border: 1px solid var(--color-accent-dark); /* Border color from the palette */
    position: relative;
  }
  
  /* Custom Radio Button Styles */
  .radio-group input[type="radio"] + span {
    height: 20px;
    width: 20px;
    border-radius: 50%; /* Circular shape */
    display: inline-block;
    margin-right: 10px;
    border: 1px solid var(--color-accent-dark); /* Border color from the palette */
    position: relative;
  }
  
  /* Checkmark/indicator of selection */
  .radio-group input[type="radio"]:checked + span::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: var(--color-accent);
    transform: translate(-50%, -50%);
  }
  
  /* Label text styles */
  .radio-group label span.label-text {
    color: var(--color-black);
    font-size: 14px; /* Slightly smaller label text */
  }

  .checkbox-group {
    display: flex;
    align-items: center;
  }
  
  .custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }
  
  .custom-checkbox .checkmark {
    height: 20px;
    width: 20px;
    border-radius: 50%; /* To match the radio buttons */
    border: 1px solid var(--color-accent-dark); /* Border color from the palette */
    display: inline-block;
    margin-right: 10px; /* Adjust as necessary to match your design */
    vertical-align: middle; /* To align with the text */
    position: relative;
  }
  
  .custom-checkbox .checkmark:after {
    content: "";
    display: none;
    width: 12px;
    height: 12px;
    border-radius: 50%; /* Round shape inside the custom checkbox */
    background: var(--color-accent);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .custom-checkbox input:checked + .checkmark:after {
    display: block;
  }
  
  .label-text {
    cursor: pointer; /* Indicates the label is clickable */
    user-select: none; /* Prevents text selection */
    font-size: 0.7rem;
    font-weight: normal;
    text-transform: initial;
  }

  .additional-guests-header {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This will put the title on one side and the button on the other */
    margin-bottom: 15px; /* Spacing between the header and the form fields */
  }
  
  .additional-guests-header h2 {
    font-size: 18px; /* Match your desired font size */
    color: var(--color-black); /* Match your color theme */
    margin: 0; /* Remove default margins */
  }
  
  .add-guest-button {
    width: auto;
    text-align: center;
    font-size: 0.8rem;
  }
  
  .add-guest-button:active {
    background-color: var(--color-accent-dark); /* Slightly darker on hover */
  }

  .remove-guest-button {
    background-color: var(--color-accent); /* Use the accent color */
    border: none;
    border-radius: 50%; /* Circle shape */
    margin-left: 10px; /* Space it out from the input fields */
    cursor: pointer;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Consistent shadow with the add button */
    color: var(--color-white); /* Icon color */
  }
  
  .remove-guest-button:hover {
    background-color: var(--color-accent-dark); /* Darken on hover, like the add button */
  }
  
  /* Adjustments for the guest entry layout */
  .input-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Spread out the inputs and the button */
  }

  .input-container select, .input-container input + input {
    margin-left: 10px; /* Space out the input fields */
  }
  
  /* Icon size adjustment if needed */
  .remove-guest-button .fa-minus {
    font-size: 14px; /* Match the size with the add button's plus icon */
  }

  .disclaimer {
    font-size: 0.7rem;
    text-align: left;
  }
}

.survey-intro {
  padding: 0 25px;
}

.loading-overlay {
  position: fixed; /* or absolute, depending on your layout */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's above everything else */
}

.loader {
  color: white;
  font-size: 20px;
  /* You can add more styling or an actual spinner image/gif here */
}

textarea {
  width: calc(100% - 30px);
}

@media (max-width: 600px) {
  .survey-form {
    width: calc(100% - 30px); /* Considering padding */
  }
}