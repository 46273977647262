@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --color-white: #FFFFFF;
  --color-accent-extra-light: #FAF4ED;
  --color-accent-light: #E7D1B5;
  --color-accent: #F1A638;
  --color-accent-dark: #344734;
  --color-black: #000000;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  background-color: var(--color-white);
  color: var(--color-black);
  text-align: center; /* Centers text for all elements */
}

.app {
  margin: 0 auto; /* Centers the container horizontally */
  max-width: 600px; /* Sets the maximum width to emulate a mobile device */
  width: 100%; /* Makes the container take up 100% of the screen when the screen is smaller than 375px */
  box-shadow: 0 0 10px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow around the container to highlight it on larger backgrounds */
  min-height: 100vh; /* Optional: makes the container at least as tall as the viewport */
  background: white; /* Optional: ensures the background inside the container is white */
}

.loading-error-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it's above other content */
}

.loading-error-screen p {
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 50px;
}

/* Style inputs with accent colors */
input, select, textarea {
  background-color: var(--color-white);
  border: 1px solid var(--color-accent-light);
}

textarea {
  min-height: 100px; /* Adjust based on your needs */
  resize: vertical; /* Allow vertical resizing only */
}

/* Style buttons */
button {
  background-color: var(--color-accent);
  color: var(--color-white);
  border: none;
  transition: background-color 0.2s, transform 0.2s;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
}

button:active:not(.top-bar button) {
  background-color: var(--color-accent-dark);
  transform: scale(1.05);
}

.form-button {
    display: block;
    width: 100%;
    padding: 15px;
    margin-top: 20px;
    border: none;
    border-radius: 15px; /* More rounded corners for buttons */
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.logo {
  width: 75px;
  height: 75px;
  margin: 0 auto;
}

/* Input Styles */
input, textarea {
  width: 75%;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px; /* Rounded corners */
  border: 1px solid #ccc; /* Subtle border */
  font-size: 16px;
  color: var(--color-black);
  background-color: var(--color-white);
}

select {
  -webkit-appearance: none; /* Remove default arrow for WebKit browsers */
  -moz-appearance: none;    /* Remove default arrow for Firefox */
  appearance: none;         /* Remove default arrow for modern browsers */
  width: 100%;
  padding: 15px;
  border-radius: 10px; /* Rounded corners, similar to iOS */
  border: 1px solid #ccc; /* Subtle border */
  font-size: 16px;
  background-color: var(--color-white);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="%23344734" viewBox="0 0 18 18"><polygon points="4.5 7 9 11.5 13.5 7 15 8.5 9 14.5 3 8.5 4.5 7"/></svg>');
  background-position: right 15px center; /* Position our custom arrow on the right */
  background-repeat: no-repeat;
  background-size: 12px; /* Size of the custom arrow */
  color: var(--color-black);
  cursor: pointer; /* Indicate it's clickable */
}

/* When user focuses on the select box */
select:focus {
  outline: none;
  border-color: var(--color-accent);
  box-shadow: 0 0 0 2px var(--color-accent-light); /* iOS-like focus highlight */
}

/* Style titles and labels with accent colors */
h1, h2, h3, label {
  color: var(--color-accent-dark);
}

/* Add subtle animations on load for inputs and buttons */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

input, button {
  animation: fadeIn 0.5s ease-out;
}

/* General styles */

.app {
  text-align: center;
}

.content {
  padding-top: 20px;
  padding-bottom: 80px;
}

/* Top bar styles */

.top-bar-wrapper {
  position: sticky; /* Fixed to the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 100; /* Make sure it's above other content */
}

.top-bar {
  position: relative;
  background-color: var(--color-accent-light);
  padding: 16px;
}

.back-button {
  animation: none;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px; /* Adjust icon size */
  color: var(--color-accent-dark);
}

.question-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px; /* Adjust icon size */
  color: var(--color-accent-dark)
}

.notification-badge {
  position: absolute;
  top: 10px; /* Adjust based on your needs */
  right: 10px; /* Adjust based on your needs */
  width: 12px; /* Badge size */
  height: 12px; /* Badge size */
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

/* Navigation bar styles */

.navigation-buttons {
  display: flex;
  padding: 16px;
  position: fixed;
  max-width: 550px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-white);
  border-top: 1px solid #eaeaea; /* Separator line */
  border-bottom-left-radius: 20px; /* rounded corners on the bottom left */
  border-bottom-right-radius: 20px; /* rounded corners on the bottom right */
  z-index: 100; /* Make sure it's above other content */
}

.navigation-buttons button {
  flex: 1;
  border: none;
  border-radius: 5px;
  padding: 10px 0;
  font-size: 12px;
  margin: 4px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navigation-buttons .navigation-button-wrapper {
  display: block;
  margin-bottom: 5px;
}

.navigation-buttons button.active {
  background-color: var(--color-accent-light);
  color: var(--color-accent-dark);
}

.navigation-buttons button:not(:last-child) {
  margin-right: 10px; /* Space between buttons */
}

/* Welcome screen styles */

.hero-image {
  height: 200px;
  background-color: #eaeaea;
  border-radius: 20px; /* Rounded corners for the image */
  margin: 16px auto;
  width: calc(100% - 32px); /* Account for padding */
  overflow: hidden; /* Ensures the image doesn't break the rounded corners */
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block; /* Remove any extra space below the image */
}

.property-info {
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  box-sizing: border-box;
}

.property-info .hero-image {
  flex: 1 1 auto;
}

.property-info-content {
  flex: 0 0 auto; /* Prevent text content from growing or shrinking */
  text-align: center;
}

.welcome-message {
  padding: 16px;
  text-align: center;
}

.welcome-message h2 {
  font-size: 1.5em; /* Larger font size for the headline */
  margin-bottom: 0.5em; /* Add some space below the headline */
  font-weight: bold; /* Normal weight for a more elegant look */
}

.welcome-message > p {
  text-align: left;;
  color: #666; /* A lighter color for the body text */
  margin-bottom: 1em; /* Space after the paragraph */
}

/* House info tab */

.house-info-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Creates a responsive grid */
  gap: 16px;
  padding: 16px;
  background-color: #fff; /* Assuming a white background */
}

.house-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid #eaeaea; /* Light border for each tile */
  border-radius: 10px; /* Rounded corners for each tile */
}

.house-info-item h2 {
  font-size: 16px;
  margin-top: 8px;
}

.house-info-detail-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.house-info-detail-back-button {
  color: var(--color-accent-dark);
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  margin-right: 25px;
  position: absolute;
  left: 15px;
  top: -22px;
}

.house-info-detail-header h1 {
  flex-grow: 1;
  text-align: center; /* Center the title */
}

.house-info-detail-content {
  padding: 16px;
}

.house-info-detail-content img {
  max-width: 100%;
}

.search-box {
  display: flex;
  align-items: center;
  background-color: var(--color-accent-extra-light);
  border-radius: 15px;
  padding: 0px 15px;
  width: 90%;
  margin: 10px auto;
  box-sizing: border-box;
}

.search-box input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%; /* Take up remaining space */
  font-size: 14px; /* Adjust font size to better fit the design */
  color: #333;
}

@media (max-width: 600px) {
  .search-box input {
    width: calc(100% - 32px); /* Considering padding */
  }
}

/* Ask question screen */

.ask-container {
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.5s ease; /* Animate the movement */
}

.response-container {
  margin-top: 2rem;
  padding: 10px;
  border-radius: 10px;
  max-width: 400px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.chat-container {
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
}

.chat-messages {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1; /* Takes up all available space */
  padding-bottom: 75px; /* Give some space above the input box */
  padding-top: 25px;
}

.chat-input {
  position: fixed; /* Fixes the input at the bottom */
  bottom: 0; /* Zero distance from the bottom */
  left: 0; /* Aligns to the left */
  width: 100%; /* Full width of the screen */
  background: #fff;
  box-shadow: 0 -1px 10px rgba(0,0,0,0.1);
  z-index: 1000; /* Ensures it's above other elements */
}

.message {
  margin: 5px;
  padding: 0 10px;
  border-radius: 10px;
  max-width: 70%;
  word-wrap: break-word;
  text-align: left;
}

.received {
  background-color: var(--color-accent-extra-light);
  align-self: flex-start;
}

.sent {
  background-color: var(--color-accent-light);
  align-self: flex-end;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background-color: var(--color-accent);
  color: white;
  cursor: pointer;
}

.chat-input button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.typing-indicator {
  color: gray;
  font-style: italic;
}

/* Demo page styles */

.demo {
  padding: 16px;
}

.demo input {
  margin-bottom: 30px;
}

.demo-banner {
  background-color: var(--color-accent-dark);
  color: var(--color-white);
  padding: 5px;
  text-align: center;
  top: 0;
  z-index: 1000;
  display: block;
  text-decoration: none;
  font-size: 0.8rem;
}

/* Survey styles */
.survey-form input,
.survey-form textarea {
  width: 100%;
  box-sizing: border-box;
}

/* Survey success styles */

.survey-success {
  padding: 16px;
  text-align: center;
}

.survey-success ul {
  text-align: left;
}

/* Add to home screen styles */

.add-to-home-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-accent-dark);
  color: var(--color-white);
  padding: 20px;
  text-align: center;
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.add-to-home-screen.show {
  transform: translateY(0);
}

.add-to-home-screen h3 {
  margin-top: 0;
  color: var(--color-white);
}

.add-to-home-screen p {
  margin: 10px 0;
}

.add-to-home-screen .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--color-white);
  font-size: 20px;
  cursor: pointer;
}

.add-to-home-screen .share-icon {
  margin-bottom: 15px;
}

.add-to-home-screen .remind-later {
  background: none;
  border: 1px solid var(--color-white);
  color: var(--color-white);
  padding: 10px 15px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 5px;
}

.add-to-home-screen .remind-later:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes easeInWithBounce {
  0% {
    transform: translateY(100%);
  }
  70% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.add-to-home-screen.show {
  animation: easeInWithBounce 0.7s ease-out forwards;
}